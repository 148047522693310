<template>
  <div class="book-detail">
    <p class="title">过程管理方法</p>
    <div>
      <p class="ptittle">书本介绍</p>
      <span
        >要打造一流的企业，就是要打造一流的过程。本书自始至终都在体现目前流行的“过程方法”的应用，并融入多年的实践经验与探索。相信对正要进行流程优化、完善和提升管理体系的企业有所帮助！</span
      >
    </div>
    <br />
    <div>
      <p class="ptittle">内容介绍</p>
      <span
        >很多企业面临困境的时候很容易就会想到流程优化。不过，对流程优化跃跃欲试的企业只钟情于成功的一面，殊不知不成功的例子比成功的多得多。不是没有决心，不是没有专家，而是每次的实践都不一样，每次都摸着石头过河，而真正的主角不是专家而是企业自己，专家能有三成的帮助已经了不起了。那么，能否有一种方法完全由企业自己去主导过程优化呢？笔者一直在探索这种方法，本书向大家呈现的就是它的研究成果。</span
      >
      <br />
      <br />
      <div class="divp">
        <span>本书分为10个章节：</span>
        <p><span>第一章</span><span>过程的基本概念</span></p>
        <p><span>第二章</span><span>过程识别</span></p>
        <p><span>第三章</span><span>过程基本要素</span></p>
        <p><span>第四章</span><span>过程接口</span></p>
        <p><span>第五章</span><span>过程业绩</span></p>
        <p><span>第六章</span><span>过程控制四个层次</span></p>
        <p><span>第七章</span><span>过程职责优化</span></p>
        <p><span>第八章</span><span>系统优化</span></p>
        <p><span>第九章</span><span>审核的过程方法</span></p>
        <p><span>第十章</span><span>管理评审的过程方法</span></p>
      </div>
      <br />
      <span
        >要打造一流的企业，就是要打造一流的过程。“过程方法”是目前最流行的管理方法，也被实践证明是流程优化最有效的方法，笔者已有多年的实践经验。本书已将“过程方法”的精髓和盘托出，其应用已在很多企业取得成功，相信本书对正要进行流程优化、完善和提升管理体系的企业有所帮助！</span
      >
      <br />
    </div>
  </div>
</template>
  
  <script>
export default {};
</script>
  
  <style lang="less" scoped>
@import "../../../assets/style/BookStyle.css";
</style>